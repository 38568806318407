$primary: #b32333;
$danger: hsl(0deg 100% 50%);

$input-box-shadow: unset;
$input-focus-box-shadow: unset;
$input-focus-border-color: $primary;

$form-select-focus-box-shadow: $input-box-shadow;

$input-border-radius: unset;
$input-border-width: 2px;
$form-floating-label-opacity: .65;
$form-floating-padding-x: 0;
$spinner-border-width-sm: .12em;
$btn-close-width: .8em;
$btn-close-focus-shadow: unset;

$carousel-control-width: 12%;
$carousel-control-icon-width: 1.5rem;
$carousel-transition-duration: .5s;
$offcanvas-vertical-height: unset;
$btn-border-radius: 2rem;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;

//$breadcrumb-divider: quote(">");

//$btn-close-color:            black;

//$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$btn-close-color}' viewBox='0 0 16 16'><path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'/></svg>");


@import "../node_modules/bootstrap/scss/bootstrap";